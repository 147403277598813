import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Column, { AutoColumn, ColumnCenter } from 'components/Column'
import { TYPE } from 'theme'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import LineChart from 'components/LineChart/alt'
import { useProtocolData, useProtocolChartData, useProtocolTransactions } from 'state/protocol/hooks'
import { formatAmount, formatDollarAmount } from 'utils/numbers'
import { PageWrapper, ColorBackground } from 'pages/styled'
import { unixToDate } from 'utils/date'
import BarChart from 'components/BarChart/alt'
import TransactionsTable from '../../components/TransactionsTable'
import { WorkSans, BaiJamjureeer } from 'components/shared'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { useTransformedVolumeData } from 'hooks/chart'
import { SmallOptionButton } from 'components/Button'
import { TransactionType, VolumeWindow } from 'types'
import { IncubationTokenRevenue, IncubationTokenSymbols, notEmpty, PoolLabels } from 'utils'
import { StakedWomData } from 'state/protocol/reducer'
import { WomSupplyData } from 'data/protocol/overview'
import { ONE_DAY_SECONDS } from 'constants/intervals'
import { useAllAssetData } from 'state/assets/hooks'
import AssetTable from 'components/assets/AssetTable'
import { IncubationTokens } from 'utils'
import { ProjectLogo } from 'components/CurrencyLogo'
import { fetchCurrentTokenPrice } from 'hooks/useTokenPrice'
import { MouseoverTooltip } from 'components/Tooltip'
import useBreakpoints from 'hooks/useBreakpoints'
import { useCirculatingSupply, useTotalLockedWom } from 'hooks/useWombatApi'
import { TransactionSort } from 'components/TransactionsSort'
import { SupportedNetwork } from 'constants/networks'
import ShareIcon from '../../wombat-assets/icons/share.svg'

const ChartAndInfoSection = styled(RowBetween)`
  position: relative;
  width: 100%;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`

interface InfoWrapperProps {
  height?: string
}

const InfoWrapper = styled.div<InfoWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.bg2};
  background-color: white;
  background: white;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  border-radius: 13px;
  padding: 23px;
  height: ${(props) => props.height || '50%'};
  justify-content: space-between;
`

export const WhiteLineChart = styled(LineChart)`
  background-color: white;
`

export const WhiteBarChart = styled(BarChart)`
  background-color: white;
`

const ChartWrapper = styled.div`
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.bg2};
  background-color: white;
  background: white;
  display: flex;
  padding: 0;
  opacity: 0.8;
  border-radius: 13px;
  background-color: white;
  background: white;
  height: 100%;
  padding: 7px 10px;
  padding-bottom: 20px;
`

const Vertical = styled.div`
  height: 100%;
  border: 1px solid ${({ theme }) => theme.wombatBrown1};
  margin: 5px auto;
`

const ColumGap = styled.div`
  width: 5%;
  border: 0px solid black;
  margin-bottom: 20px;
  @media screen and (max-width: 1300px) {
    margin-bottom: 14px;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 8px;
  }
  @media screen and (max-width: 720px) {
    margin-bottom: 20px;
  }
`

const StakePercentageChangesContainer = styled.div`
  display: flex;
  flex: 1;
`
const Link = styled.a`
  text-decoration: none;
  color: #695b51;
`

const StyledShareIcon = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: inline-block;
  vertical-align: -2px;
  margin-left: 4px;
`

function getInitIncubationRevenue(): IncubationTokenRevenue<number | null> {
  return {
    [IncubationTokenSymbols.MGP]: 0,
    [IncubationTokenSymbols.QUO]: 0,
    [IncubationTokenSymbols.WMX]: 0,
    [IncubationTokenSymbols.SABLE]: 0,
  }
}

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { isMobileLg, isTabletLg } = useBreakpoints()

  const [activeNetwork] = useActiveNetworkVersion()

  const [protocolData] = useProtocolData()
  const [chartData] = useProtocolChartData()
  const [transactions] = useProtocolTransactions()
  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [stakedWomData, setStakedWomData] = useState<StakedWomData | undefined>()
  const [womSupplyData, setWomSupplyData] = useState<WomSupplyData | undefined>()
  const [totalFeeCollected, setTotalFeeCollected] = useState<number | undefined>()
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [leftLabel, setLeftLabel] = useState<string | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()
  const [incubationRevenue, setIncubationRevenue] = useState<IncubationTokenRevenue<number | null>>(
    getInitIncubationRevenue()
  )
  const [totalIncubationRevenue, setTotalIncubationRevenue] = useState<number>()
  const [totalBribeRevenue, setTotalBribeRevene] = useState<number | undefined>()

  const allAssets = useAllAssetData()
  const formattedAssets = useMemo(() => {
    return Object.values(allAssets)
      .map((t) => t.data)
      .filter(notEmpty)
  }, [allAssets])

  const filteredAsset = formattedAssets.filter((asset) => {
    return (
      !!asset &&
      asset.token.toLowerCase() != '0xe85afccdafbe7f2b096f268e31cce3da8da2990a' && // aBNBc
      asset.token.toLowerCase() != '0x4db5a66e937a9f4473fa95b1caf1d1e1d62e29ea' && // WETH
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? asset.tvlUSD > 500
        : true,
      asset.pool !== PoolLabels.BNB
    )
  })

  let totalLiquidityUSD = 0
  filteredAsset.forEach((asset) => {
    totalLiquidityUSD += asset.tvlUSD
  })

  // useEffect(() => {
  //   const _incubationRevenue = getInitIncubationRevenue()
  //   let totalRevenue = 0
  //   IncubationTokens.forEach(async (token) => {
  //     const revenue = (await fetchCurrentTokenPrice('bsc', token.address)) * token.quantity
  //     _incubationRevenue[token.symbol] = revenue
  //     totalRevenue += revenue
  //     setIncubationRevenue(_incubationRevenue)
  //     setTotalIncubationRevenue(totalRevenue)
  //   })
  // }, [])

  useEffect(() => {
    const _incubationRevenue = getInitIncubationRevenue()
    ;(async () => {
      const prices = await fetchCurrentTokenPrice(IncubationTokens)
      let totalRevenue = 0
      IncubationTokens.forEach(async (token) => {
        const revenue = prices ? Number(prices[token.coinGeckoId].usd) * token.quantity : 0
        _incubationRevenue[token.symbol] = revenue
        totalRevenue += revenue
        setIncubationRevenue(_incubationRevenue)
        setTotalIncubationRevenue(totalRevenue)
      })
    })()
  }, [])

  useEffect(() => {
    setLiquidityHover(undefined)
    setVolumeHover(undefined)
  }, [activeNetwork])

  // if hover value undefined, reset to current day value
  useEffect(() => {
    if (volumeHover === undefined && protocolData) {
      setVolumeHover(protocolData.volumeUSD)
    }
  }, [protocolData, volumeHover])
  useEffect(() => {
    if (stakedWomData === undefined && protocolData) {
      const _stakedWomData = protocolData.stakedWomData[activeNetwork.id]
      setStakedWomData(_stakedWomData)
    }
  }, [activeNetwork.id, protocolData, stakedWomData])
  useEffect(() => {
    if (womSupplyData === undefined && protocolData) {
      setWomSupplyData(protocolData.womSupplyData)
    }
  }, [protocolData, womSupplyData])
  useEffect(() => {
    if (totalFeeCollected === undefined && protocolData) {
      setTotalFeeCollected(protocolData.totalFeeCollected)
    }
  }, [protocolData, totalFeeCollected])
  useEffect(() => {
    if (totalBribeRevenue === undefined && protocolData) {
      setTotalBribeRevene(protocolData.totalBribeRevenue)
    }
  }, [protocolData, totalBribeRevenue])
  const formattedTvlData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.tvlUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])
  useEffect(() => {
    if (liquidityHover === undefined && formattedTvlData.length) {
      if (totalLiquidityUSD > 0) {
        setLiquidityHover(totalLiquidityUSD)
      }
    }
  }, [protocolData, liquidityHover, formattedTvlData, totalLiquidityUSD])
  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.volumeUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const weeklyVolumeData = useTransformedVolumeData(chartData, 'week')
  const monthlyVolumeData = useTransformedVolumeData(chartData, 'month')
  const [volumeWindow, setVolumeWindow] = useState(VolumeWindow.daily)
  let staked24h = 0,
    staked24hPC = 0,
    staked7d = 0,
    staked7dPC = 0,
    avgLockTime = 0,
    currentTotalSupply = 0
  if (stakedWomData) {
    staked24h = stakedWomData.stakedToday - stakedWomData.stakedYesterday || 0
    staked24hPC = (stakedWomData.stakedYesterday ? staked24h / stakedWomData.stakedYesterday : 0) * 100
    staked7d = stakedWomData.stakedToday - stakedWomData.stakedLastWeek || 0
    staked7dPC = (stakedWomData.stakedLastWeek ? staked7d / stakedWomData.stakedLastWeek : 0) * 100
    avgLockTime = stakedWomData.avgLockTimeInSec / ONE_DAY_SECONDS
    currentTotalSupply = stakedWomData.currentTotalSupply
  }

  const stakedToday = stakedWomData?.stakedToday ?? '0'
  const circulatingSupply = useCirculatingSupply() ?? 0
  const totalLockedWom = useTotalLockedWom() ?? 0
  const circulatingSupplyStakedPercentage =
    (Number(stakedToday) / (Number(circulatingSupply) + Number(totalLockedWom))) * 100

  const [txFilter, setTxFilter] = useState<TransactionType | undefined>(undefined)

  return (
    <PageWrapper>
      <ColorBackground />
      <AutoColumn gap="16px">
        <ChartAndInfoSection style={{ gap: '16px', alignItems: 'flex-start' }}>
          <Column style={{ width: '100%', height: '100%' }} gap="6px">
            <ChartWrapper>
              <AutoColumn gap="4px" style={{ padding: '14px 14px 0px 14px' }}>
                <BaiJamjureeer>
                  <TYPE.mediumHeader fontSize="18px">LIQUIDITY</TYPE.mediumHeader>
                </BaiJamjureeer>
                <TYPE.largeHeader fontSize={isMobileLg ? '16px' : '18px'}>
                  <WorkSans>{formatDollarAmount(liquidityHover, 2, true)} </WorkSans>
                </TYPE.largeHeader>
                <TYPE.main fontSize="12px" height="14px">
                  {leftLabel ? <WorkSans>{leftLabel} (UTC)</WorkSans> : null}
                </TYPE.main>
              </AutoColumn>
              <WhiteLineChart
                data={formattedTvlData}
                color={'wombatPurple0'}
                value={liquidityHover}
                label={leftLabel}
                setValue={setLiquidityHover}
                setLabel={setLeftLabel}
              />
            </ChartWrapper>
            <ChartWrapper>
              <RowBetween style={{ padding: '14px 14px 0px 14px' }}>
                <AutoColumn gap="4px">
                  <BaiJamjureeer>
                    <TYPE.mediumHeader fontSize="18px">VOLUME [24H]</TYPE.mediumHeader>
                  </BaiJamjureeer>
                  <TYPE.largeHeader fontSize={isMobileLg ? '16px' : '18px'}>
                    <WorkSans> {formatDollarAmount(volumeHover, 2)}</WorkSans>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {rightLabel ? <WorkSans>{rightLabel} (UTC)</WorkSans> : null}
                  </TYPE.main>
                </AutoColumn>
                <RowFixed style={{ marginLeft: '-40px', marginTop: '8px' }}>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.daily}
                    onClick={() => setVolumeWindow(VolumeWindow.daily)}
                  >
                    D
                  </SmallOptionButton>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.weekly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.weekly)}
                  >
                    W
                  </SmallOptionButton>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.monthly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.monthly)}
                  >
                    M
                  </SmallOptionButton>
                </RowFixed>
              </RowBetween>
              <WhiteBarChart
                height={300}
                data={
                  volumeWindow === VolumeWindow.monthly
                    ? monthlyVolumeData
                    : volumeWindow === VolumeWindow.weekly
                    ? weeklyVolumeData
                    : formattedVolumeData
                }
                setValue={setVolumeHover}
                setLabel={setRightLabel}
                value={volumeHover}
                label={rightLabel}
                activeWindow={volumeWindow}
              />
            </ChartWrapper>
          </Column>
          <Column style={{ width: '100%', height: '100%' }} gap="6px">
            {/* Locking */}
            {/* Hide Locking Section For ETH */}
            <InfoWrapper>
              <AutoColumn gap="4px">
                <BaiJamjureeer>
                  <TYPE.mediumHeader fontSize="18px">LOCKING</TYPE.mediumHeader>
                </BaiJamjureeer>
              </AutoColumn>
              <RowBetween>
                {/* <Column style={{ flex: '1' }}>
                <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>Total WOM Locked</TYPE.subHeader>
                <TYPE.largeHeader fontSize={isMobileLg ? '14px' : '16px'}>
                  {stakedToday && +stakedToday > 0 ? formatAmount(Number(stakedToday)) : ' - '}
                </TYPE.largeHeader>
              </Column> */}
                <StakePercentageChangesContainer>
                  <ColumnCenter style={{ gap: '4px', flexWrap: 'wrap', flex: '1' }}>
                    <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>Lock (24H)</TYPE.subHeader>
                    <TYPE.subHeader color={'#B672D6'} fontWeight={500} fontSize={isMobileLg ? '13px' : '15px'}>
                      {staked24h > 0 ? '+' : ''}
                      {formatAmount(Number(staked24h))}({staked24hPC > 0 ? '+' : ''}
                      {Number(staked24hPC).toFixed(2)}%)
                    </TYPE.subHeader>
                    <Vertical />
                  </ColumnCenter>
                  <ColumnCenter style={{ gap: '4px', flexWrap: 'wrap', flex: '1' }}>
                    <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>Lock (7D)</TYPE.subHeader>
                    <TYPE.subHeader color={'#B672D6'} fontWeight={500} fontSize={isMobileLg ? '13px' : '15px'}>
                      {staked7d > 0 ? '+' : ''}
                      {formatAmount(Number(staked7d))}({staked7dPC > 0 ? '+' : ''}
                      {Number(staked7dPC).toFixed(2)}%)
                    </TYPE.subHeader>
                  </ColumnCenter>
                </StakePercentageChangesContainer>
              </RowBetween>
              {isMobileLg ? <ColumGap /> : <></>}
              <Column gap="10px">
                <RowBetween>
                  <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>Circulating Supply Locked</TYPE.subHeader>
                  <TYPE.largeHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    {circulatingSupplyStakedPercentage.toFixed(2)}%
                  </TYPE.largeHeader>
                </RowBetween>
                <RowBetween>
                  <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>Average Lock Period</TYPE.subHeader>
                  <TYPE.largeHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    {avgLockTime.toFixed(2)}&nbsp;DAY(S)
                  </TYPE.largeHeader>
                </RowBetween>
                <RowBetween>
                  <TYPE.subHeader>Total veWOM Supply</TYPE.subHeader>
                  <TYPE.largeHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    {formatAmount(Number(currentTotalSupply))}&nbsp;veWOM
                  </TYPE.largeHeader>
                </RowBetween>
                <RowBetween>
                  <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>Total WOM Locked</TYPE.subHeader>
                  <TYPE.largeHeader fontSize={isMobileLg ? '14px' : '16px'}>
                    {stakedToday && +stakedToday > 0 ? formatAmount(Number(stakedToday)) : ' - '}
                  </TYPE.largeHeader>
                </RowBetween>
              </Column>
              {/* Revenue */}
            </InfoWrapper>

            <InfoWrapper height={isTabletLg && activeNetwork.id === SupportedNetwork.ETHEREUM ? '100%' : '50%'}>
              <AutoColumn gap="4px">
                <BaiJamjureeer>
                  <TYPE.mediumHeader fontSize="18px">REVENUE</TYPE.mediumHeader>
                </BaiJamjureeer>
              </AutoColumn>
              <AutoColumn>
                <RowBetween marginBottom="10px">
                  <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    <WorkSans>Cumulative Protocol Fees (USD)</WorkSans>
                    <MouseoverTooltip text="Fees collected from swapping, which are then reserved." showIcon />
                  </TYPE.subHeader>
                  <TYPE.largeHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    <WorkSans>
                      {totalFeeCollected && totalFeeCollected > 0 ? `${formatDollarAmount(totalFeeCollected)}` : ' - '}
                    </WorkSans>
                  </TYPE.largeHeader>
                </RowBetween>
                <RowBetween marginBottom="10px">
                  <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    <WorkSans>Bribes Revenue</WorkSans>
                    <MouseoverTooltip
                      text="Bribes allocated to the voting gauges, which are distributed to the voters based on real-time voting APR second by second."
                      showIcon
                    />
                  </TYPE.subHeader>
                  <TYPE.largeHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    <WorkSans>
                      {totalBribeRevenue && totalBribeRevenue > 0 ? `${formatDollarAmount(totalBribeRevenue)}` : ' - '}
                    </WorkSans>
                  </TYPE.largeHeader>
                </RowBetween>
                <RowBetween marginBottom="10px">
                  <TYPE.subHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    <WorkSans>Incubation Revenue (USD)</WorkSans>
                    <MouseoverTooltip
                      text="Wombat invests in projects building on top of us. In return, these projects give a portion of their tokens to us as an extra layer of revenue."
                      showIcon
                    />
                  </TYPE.subHeader>
                  <TYPE.largeHeader fontSize={isMobileLg ? '13px' : '15px'}>
                    <WorkSans>
                      {totalIncubationRevenue && totalIncubationRevenue > 0
                        ? `${formatDollarAmount(totalIncubationRevenue)}`
                        : ' - '}
                    </WorkSans>
                  </TYPE.largeHeader>
                </RowBetween>
              </AutoColumn>
              <RowBetween width="100%">
                <AutoColumn style={{ width: '100%', position: 'relative' }}>
                  <TYPE.largeHeader fontSize="19px">
                    <AutoRow height="100%" style={{ justifyContent: 'space-around', textAlign: 'center', flex: '1' }}>
                      {IncubationTokens.map((data, i) => {
                        if (data) {
                          return (
                            <React.Fragment key={i}>
                              <WorkSans>
                                <AutoColumn gap="4px" style={{ marginBottom: '16px' }}>
                                  <AutoRow justify="center">
                                    <AutoColumn justify="center">
                                      <ProjectLogo address={data.name} size="20px" />
                                    </AutoColumn>
                                    <AutoColumn>
                                      <TYPE.subHeader fontSize={isMobileLg ? '13px' : '16px'} fontWeight={500}>
                                        &nbsp;
                                        {data.link ? (
                                          <Link href={data.link} target="_blank" rel="noreferrer">
                                            {data.name}
                                            <StyledShareIcon src={ShareIcon} size={'16px'}></StyledShareIcon>
                                          </Link>
                                        ) : (
                                          <span>{data.name}</span>
                                        )}
                                      </TYPE.subHeader>
                                    </AutoColumn>
                                  </AutoRow>
                                  <AutoColumn>
                                    <TYPE.subHeader
                                      fontSize={isMobileLg ? '13px' : '17px'}
                                      color="wombatPurple0"
                                    >{`${formatAmount(data.quantity)}  ${data.symbol}`}</TYPE.subHeader>
                                    <TYPE.subHeader color="wombatPurple0">{`(${formatDollarAmount(
                                      incubationRevenue[data.symbol]
                                    )})`}</TYPE.subHeader>
                                  </AutoColumn>
                                </AutoColumn>
                              </WorkSans>
                              {i !== IncubationTokens.length - 1 && <Vertical style={{ margin: 0 }} />}
                            </React.Fragment>
                          )
                        }
                        return null
                      })}
                    </AutoRow>
                  </TYPE.largeHeader>
                </AutoColumn>
              </RowBetween>
            </InfoWrapper>
          </Column>
        </ChartAndInfoSection>
        <RowBetween>
          <TYPE.main fontSize={18} fontWeight={700} color={'black'}>
            <WorkSans>Top Tokens</WorkSans>
          </TYPE.main>
        </RowBetween>
        <AssetTable assetDatas={filteredAsset} maxItems={10} />
        <RowFixed>
          <TYPE.main fontSize={18} fontWeight={700} color={'black'} marginRight={20}>
            <WorkSans>Transactions</WorkSans>
          </TYPE.main>
          <TransactionSort txFilter={txFilter} setTxFilter={setTxFilter}></TransactionSort>
        </RowFixed>
        {transactions ? (
          <TransactionsTable
            transactions={transactions}
            maxItems={10}
            color={activeNetwork.primaryColor}
            txFilter={txFilter}
          />
        ) : null}
      </AutoColumn>
    </PageWrapper>
  )
}
